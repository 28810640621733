<template>
  <div>
    <div class="inline-block cursor-pointer">
      <span :class="[{ 'flex items-start': labeltop }, { 'flex items-center': labelcenter }]">
        <input
          :id="props.id"
          :name="props.name"
          :checked="isActive"
          v-bind="{
            ...(props.disabled ? { disabled: 'disabled' } : {})
          }"
          :class="[
            { 'border-neutral-500 bg-white': !props.error },
            { 'border-cta-700 bg-cta-50': props.error },
            { 'disabled-ui-checkbox': props.disabled },
            { 'cursor-pointer': !props.disabled },
            'custom-checkbox-input'
          ]"
          :title="props.title"
          type="checkbox"
          :aria-label="props.accessibilityLabel || props.title"
          @click="toggle"
        >
        <template v-if="$slots.label">
          <label :for="props.id" :class="[ { 'text-cta-700': props.error }, { 'pl-4': !props.designLabel }, { 'cursor-pointer': props.id }, props.designLabel ]">
            <slot name="label" />
          </label>
        </template>
      </span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { getRandomId } from '~/utils/Utils'
const props = defineProps({
  id: {
    type: String,
    default: getRandomId
  },
  name: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  accessibilityLabel: {
    type: String,
    default: ''
  },
  designLabel: {
    type: String,
    default: null
  },
  labelPosition: {
    type: String,
    default: 'center'
  },
  modelValue: {
    type: [Boolean, String, Number, Array],
    default: null
  },
  value: {
    type: [String, Number, Array],
    default: ''
  },
  trueValue: {
    type: [Boolean, String, Number],
    default: true
  },
  falseValue: {
    type: [Boolean, String, Number],
    default: false
  },
  disabled: {
    type: [Boolean],
    default: false
  },
  error: {
    type: Boolean,
    default: false
  }
})

const labelcenter = computed(() => props.labelPosition === 'center')
const labeltop = computed(() => props.labelPosition === 'top')

const emit = defineEmits<{(e: 'update:modelValue', value: boolean|string|number|string[]): void}>()

const isActive = computed((): boolean => {
  if (!Array.isArray(props.modelValue) && (props.trueValue !== true || props.falseValue !== false)) {
    return props.modelValue === props.trueValue
  }

  if (!Array.isArray(props.modelValue)) {
    return !!props.modelValue
  }

  if (!Array.isArray(props.value)) {
    return props.modelValue.includes(props.value)
  }

  for (const element of props.value) {
    if (!props.modelValue.includes(element)) {
      return false
    }
  }
  return true
})

const toggle = (): void => {
  if (!Array.isArray(props.modelValue)) {
    emit('update:modelValue', !isActive.value ? props.trueValue : props.falseValue)
    return
  }

  if (!Array.isArray(props.value) && !props.modelValue.includes(props.value)) {
    emit('update:modelValue', [...props.modelValue, props.value])
    return
  }

  if (!Array.isArray(props.value) && props.modelValue.includes(props.value)) {
    emit('update:modelValue', props.modelValue.filter(x => x !== props.value))
    return
  }

  for (const element of props.value) {
    if (!props.modelValue.includes(element)) {
      emit('update:modelValue', [...props.modelValue, ...props.value])
      return
    }
  }

  emit('update:modelValue', props.modelValue.filter(x => !props.value?.includes(x)))
}
</script>

<style lang="scss" scoped>
.disabled-ui-checkbox {
  color: lightgrey !important;
  background-color: lightgrey !important;
  pointer-events: none !important;
  user-select: none !important;
}
input[type=checkbox].custom-checkbox-input {
  @apply text-white rounded focus:outline-none focus:border-neutral-200 focus:ring focus:ring-transparent;
  width: 1.5em;
  height: 1.5em;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
  &:checked{
    @apply bg-petrol-500 border-petrol-500 text-white;
    &:hover, &:active, &:focus{
      @apply bg-petrol-500 border-petrol-500 text-white;
    }
  }
}
</style>
